import { useIntl } from 'react-intl';
import { Card, Icon } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

const EventCard = ({
  event,
  isEditMode,
  showTime,
  showDescription,
  moment: momentlib,
}) => {
  const intl = useIntl();
  const moment = momentlib.default;
  moment.locale(intl.locale);
  const mStart = moment(event.start);
  if (event.whole_day && mStart.hour() === 23) {
    /* fixes https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/732 */
    mStart.add('1', 'hour');
  }
  return (
    <ConditionalLink condition={!isEditMode} to={event['@id']}>
      <Card className="event-card">
        <div className="event-content">
          <div className="col-left">
            <div>
              <span className="day">{mStart.format('D')}.</span>
              <span className="month">{mStart.format('MMM')}</span>
            </div>
            {showTime && !event.whole_day && (
              <span className="time">{mStart.format('HH:mm')}</span>
            )}
          </div>
          <div className="col-right awh">
            <div>
              <span className="title">{event.title}</span>
              {showDescription && (
                <span className="description">{event.description}</span>
              )}
              <span className="location">
                {event.location && (
                  <>
                    <span>
                      <Icon name="map marker alternate" size="small" />{' '}
                      {event.location}
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </Card>
    </ConditionalLink>
  );
};

export default injectLazyLibs(['moment'])(EventCard);
