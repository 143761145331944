import { createRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Dimmer, Label, Loader, Segment, Pagination } from 'semantic-ui-react';
/* eslint-disable-next-line import/no-unresolved */
import withQuerystringResults from '@plone/volto-original/components/manage/Blocks/Listing/withQuerystringResults';
import { Icon } from '@plone/volto/components';
import config from '@plone/volto/registry';
import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';
import { DropdownWithCheckboxes } from '@package/components';

const messages = defineMessages({
  labelPressRelease: {
    id: 'Press release',
    defaultMessage: 'Press release',
  },
  labelRelease: {
    id: 'Release',
    defaultMessage: 'Release',
  },
  labelType: {
    id: 'Type',
    defaultMessage: 'Type',
  },
  labelTags: {
    id: 'Tags',
    defaultMessage: 'Tags',
  },
  labelFilter: {
    id: 'Filter',
    defaultMessage: 'Filter',
  },
});

const Filters = ({
  items = [],
  always_display_filters_as_dropdown = false,
  showTypeFilter,
  filterTypes,
  setFilterTypes,
  showSubjectsFilter,
  filterSubjects,
  setFilterSubjects,
}) => {
  const intl = useIntl();

  let allTypes = [];
  let allSubjects = [];

  items = items.map((item) => {
    if (showTypeFilter && allTypes.indexOf(item.typeLabel) === -1)
      allTypes.push(item.typeLabel);

    if (showSubjectsFilter && item.Subject && item.Subject.length > 0)
      allSubjects = [...new Set([...allSubjects, ...item.Subject])];

    return item;
  });

  allSubjects.sort();
  allTypes.sort();

  const toggleTypeFilter = (typeLabel) => {
    if (filterTypes.indexOf(typeLabel) > -1) {
      setFilterTypes(filterTypes.filter((item) => item !== typeLabel));
    } else {
      setFilterTypes(filterTypes.concat(typeLabel));
    }
  };

  const toggleSubjectFilter = (subject) => {
    if (filterSubjects.indexOf(subject) > -1) {
      setFilterSubjects(filterSubjects.filter((item) => item !== subject));
    } else {
      setFilterSubjects(filterSubjects.concat(subject));
    }
  };

  const asDropdown = always_display_filters_as_dropdown
    ? true
    : typeof window !== 'undefined' && window.innerWidth < 992;

  if ((showTypeFilter || showSubjectsFilter) === false) return <></>;

  return (
    <>
      {asDropdown ? (
        <>
          {allTypes.length > 0 && (
            <DropdownWithCheckboxes
              title={
                showSubjectsFilter
                  ? intl.formatMessage(messages.labelType)
                  : intl.formatMessage(messages.labelFilter)
              }
              items={allTypes.map((i) => {
                return { token: i, title: i };
              })}
              filterStatus={filterTypes}
              onChange={(_, data) => toggleTypeFilter(data.value)}
            />
          )}
          {allSubjects.length > 0 && (
            <DropdownWithCheckboxes
              title={
                showTypeFilter
                  ? intl.formatMessage(messages.labelTags)
                  : intl.formatMessage(messages.labelFilter)
              }
              items={allSubjects.map((i) => {
                return { token: i, title: i };
              })}
              filterStatus={filterSubjects}
              onChange={(_, data) => toggleSubjectFilter(data.value)}
            />
          )}
        </>
      ) : (
        <Segment className="filters">
          <div>
            <b>
              <FormattedMessage id="Filter" defaultMessage="Filter" />:
            </b>
          </div>
          <div>
            {allTypes.map((typeLabel) => {
              return (
                <Label
                  className="type"
                  key={typeLabel}
                  active={filterTypes.indexOf(typeLabel) > -1 ? true : false}
                  onClick={() => {
                    toggleTypeFilter(typeLabel);
                  }}
                >
                  {typeLabel}
                </Label>
              );
            })}
          </div>
          <div>
            {allSubjects.map((subject) => {
              return (
                <Label
                  className="subject"
                  key={subject}
                  active={filterSubjects.indexOf(subject) > -1 ? true : false}
                  onClick={() => {
                    toggleSubjectFilter(subject);
                  }}
                >
                  {subject}
                </Label>
              );
            })}
          </div>
        </Segment>
      )}
    </>
  );
};

const ListingBody = withQuerystringResults((props) => {
  const {
    data = {},
    isEditMode,
    listingItems,
    totalPages,
    onPaginationChange,
    variation,
    currentPage,
    prevBatch,
    nextBatch,
    isFolderContentsListing,
    hasLoaded,
  } = props;

  const pressReleaseURL =
    '/de/verwaltung/abteilung-1/referat-kommunikation/aktuelles/pressemitteilungen';

  const releaseURL =
    '/de/verwaltung/abteilung-1/referat-kommunikation/aktuelles/meldungen';

  const intl = useIntl();

  let items = (listingItems || []).map((item) => {
    let type = item.Type || item['@type'];
    if (item['@id'].startsWith(pressReleaseURL)) {
      type = intl.formatMessage(messages.labelPressRelease);
    }
    if (item['@id'].startsWith(releaseURL)) {
      type = intl.formatMessage(messages.labelRelease);
    }

    item.typeLabel = type;
    return item;
  });

  let ListingBodyTemplate;
  // Legacy support if template is present
  const variations = config.blocks?.blocksConfig['listing']?.variations || [];
  const defaultVariation = variations.filter((item) => item.isDefault)?.[0];

  if (data.template && !data.variation) {
    const legacyTemplateConfig = variations.find(
      (item) => item.id === data.template,
    );
    ListingBodyTemplate = legacyTemplateConfig.template;
  } else {
    ListingBodyTemplate =
      variation?.template ?? defaultVariation?.template ?? null;
  }

  const listingRef = createRef();

  /* filter by Content-Type, by default all types are selected */
  const [filterTypes, setFilterTypes] = useState([]);
  /* filter by subjects / tags, by default no tag is selected -
     filter is only applied when at least one tag is selected */
  const [filterSubjects, setFilterSubjects] = useState([]);

  const allowed_filters = data?.allowed_filters || [];
  const always_display_filters_as_dropdown =
    data?.always_display_filters_as_dropdown || false;
  const showTypeFilter = allowed_filters.indexOf('type') > -1;
  const showSubjectsFilter = allowed_filters.indexOf('tags') > -1;

  if (allowed_filters.length > 0) {
    if (showTypeFilter && filterTypes.length > 0)
      items = items.filter((item) => filterTypes.indexOf(item.typeLabel) > -1);

    if (showSubjectsFilter && filterSubjects.length > 0)
      items = items.filter((item) => {
        for (let i = 0; i < item.Subject.length; i++) {
          if (filterSubjects.indexOf(item.Subject[i]) > -1) return true;
        }
        return false;
      });
  }

  return (
    <>
      {allowed_filters.length > 0 && (
        <Filters
          items={listingItems}
          allowed_filters={['type', 'tags']}
          always_display_filters_as_dropdown={
            always_display_filters_as_dropdown
          }
          showTypeFilter={showTypeFilter}
          filterTypes={filterTypes}
          setFilterTypes={setFilterTypes}
          showSubjectsFilter={showSubjectsFilter}
          filterSubjects={filterSubjects}
          setFilterSubjects={setFilterSubjects}
        />
      )}
      {items?.length > 0 ? (
        <>
          <div ref={listingRef}>
            <ListingBodyTemplate
              items={items}
              isEditMode={isEditMode}
              {...data}
              {...variation}
            />
            {totalPages > 1 && (
              <div className="pagination-wrapper">
                <Pagination
                  activePage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(e, { activePage }) => {
                    !isEditMode &&
                      listingRef.current.scrollIntoView({ behavior: 'smooth' });
                    onPaginationChange(e, { activePage });
                  }}
                  firstItem={null}
                  lastItem={null}
                  prevItem={{
                    content: <Icon name={paginationLeftSVG} size="18px" />,
                    icon: true,
                    'aria-disabled': !prevBatch,
                    className: !prevBatch ? 'disabled' : null,
                  }}
                  nextItem={{
                    content: <Icon name={paginationRightSVG} size="18px" />,
                    icon: true,
                    'aria-disabled': !nextBatch,
                    className: !nextBatch ? 'disabled' : null,
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : isEditMode ? (
        <div className="listing message" ref={listingRef}>
          {isFolderContentsListing && (
            <FormattedMessage
              id="No items found in this container."
              defaultMessage="No items found in this container."
            />
          )}
          {hasLoaded && (
            <FormattedMessage
              id="No results found."
              defaultMessage="No results found."
            />
          )}
          <Dimmer active={!hasLoaded} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
        </div>
      ) : (
        <div>
          {hasLoaded && (
            <FormattedMessage
              id="No results found."
              defaultMessage="No results found."
            />
          )}
          <Dimmer active={!hasLoaded} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
        </div>
      )}
    </>
  );
});

export default ListingBody;
