/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';

import card from './card/card';
import lsf_headings from './lsf/headings';
import { lsf_lecture, lsf_lectures } from './lsf/lectures';
import research_projects from './relations/projects';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  card,
  lsf_headings,
  lsf_lecture,
  lsf_lectures,
  research_projects,
};

export default reducers;
