import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { Grid } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { SummaryItem } from '@package/components';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';

const CustomLeftArrow = ({ onClick, disabled }) => {
  return (
    <button
      onClick={() => onClick()}
      disabled={disabled}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
    >
      <Icon name={arrowSVG} className="link" size="24px" color="white" />
    </button>
  );
};

const CustomRightArrow = ({ onClick, disabled }) => {
  return (
    <button
      onClick={() => onClick()}
      disabled={disabled}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
    >
      <Icon name={arrowSVG} className="link" size="24px" color="white" />
    </button>
  );
};

const CustomButtonGroupAsArrows = ({ next, previous, carouselState }) => {
  const disabledLeft = carouselState.currentSlide === 0;
  const disabledRight =
    carouselState.currentSlide + carouselState.slidesToShow >=
    carouselState.totalItems;
  return !disabledLeft || !disabledRight ? (
    <div className="react-multiple-carousel__arrows">
      <CustomLeftArrow onClick={previous} disabled={disabledLeft} />
      <CustomRightArrow onClick={next} disabled={disabledRight} />
    </div>
  ) : (
    <></>
  );
};

const SummaryTemplate = ({
  items,
  disable_slider,
  hide_preview_images,
  hide_descriptions,
  cta_text,
  isEditMode,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 9999999, min: 1280 },
      items: 4,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1280, min: 768 },
      items: 3,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      partialVisibilityGutter: 90,
    },
  };

  const [showDots, setShowDots] = useState(false);
  const refCarousel = useRef();

  useEffect(() => {
    // dont show dots when all slides are visible
    function handleResize() {
      if (
        refCarousel.current?.state &&
        refCarousel.current.state.totalItems >
          refCarousel.current.state.slidesToShow
      )
        setShowDots(true);
      else setShowDots(false);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className="summary-template">
      {disable_slider ? (
        <Grid>
          {items.map((item) => (
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={4}
              largeScreen={3}
              key={item['@id']}
            >
              <SummaryItem
                item={item}
                ctaText={cta_text}
                hideImages={hide_preview_images}
                hideDescriptions={hide_descriptions}
                isEditMode={isEditMode}
              />
            </Grid.Column>
          ))}
        </Grid>
      ) : isEditMode ? (
        <Grid>
          {items.slice(0, 4).map((item) => (
            <Grid.Column width={3} key={item['@id']}>
              <SummaryItem
                item={item}
                ctaText={cta_text}
                hideImages={hide_preview_images}
                hideDescriptions={hide_descriptions}
                isEditMode={isEditMode}
              />
            </Grid.Column>
          ))}
        </Grid>
      ) : (
        <div className="carousel-container fluid-right">
          <Carousel
            ref={refCarousel}
            autoPlay={false}
            shouldResetAutoplay={false}
            responsive={responsive}
            ssr={true}
            infinite={false}
            arrows={false}
            renderButtonGroupOutside
            customButtonGroup={<CustomButtonGroupAsArrows />}
            partialVisible={true}
            showDots={showDots}
          >
            {items.map((item) => (
              <Grid.Column width={4} key={item['@id']}>
                <SummaryItem
                  item={item}
                  ctaText={cta_text}
                  hideImages={hide_preview_images}
                  hideDescriptions={hide_descriptions}
                  isEditMode={isEditMode}
                />
              </Grid.Column>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default SummaryTemplate;
