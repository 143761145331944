import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Container, Grid, Label, Segment } from 'semantic-ui-react';
import { FormattedDate } from '@plone/volto/components';

const messages = defineMessages({
  status: {
    id: 'Status',
    defaultMessage: 'Status',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  supervisor: {
    id: 'Supervisor',
    defaultMessage: 'Supervisor',
  },
  reviewer_2: {
    id: 'Reviewer 2',
    defaultMessage: 'Reviewer 2',
  },
  reviewer_3: {
    id: 'Reviewer 3',
    defaultMessage: 'Reviewer 3',
  },
  admission_date: {
    id: 'Admission to doctoral student status',
    defaultMessage: 'Admission to doctoral student status',
  },
  finish_date: {
    id: 'Finish date',
    defaultMessage: 'Finish date',
  },
});

const DoctorateView = ({ content }) => {
  const intl = useIntl();
  const fields = [
    {
      name: intl.formatMessage(messages.name),
      value: `${content.prename} ${content.surname}`,
    },
    {
      name: intl.formatMessage(messages.status),
      value: content.status.title,
    },
    {
      name: intl.formatMessage(messages.finish_date),
      value: content.finish_date ? (
        <FormattedDate date={content.finish_date} />
      ) : (
        false
      ),
    },
    {
      name: intl.formatMessage(messages.supervisor),
      value: content.supervisor,
    },
    {
      name: intl.formatMessage(messages.reviewer_2),
      value: content.reviewer_2,
    },
    {
      name: intl.formatMessage(messages.reviewer_3),
      value: content.reviewer_3,
    },
    {
      name: intl.formatMessage(messages.admission_date),
      value: content.admission_date ? (
        <FormattedDate date={content.admission_date} />
      ) : (
        false
      ),
    },
  ];
  return (
    <Container className="doctorate-view">
      <Label>
        <FormattedMessage id="Doctorate" defaultMessage="Doctorate" />
      </Label>
      <h1>{content.title}</h1>
      <Grid stackable>
        {fields
          .filter((field) => field.value)
          .map((field) => (
            <Grid.Column width={4}>
              <Grid celled="internally">
                <Grid.Row>
                  <Label>{field.name}</Label>
                </Grid.Row>
                <Grid.Row>
                  <Segment basic>{field.value}</Segment>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          ))}
      </Grid>
      {content.abstract && <div className="abstract">{content.abstract}</div>}
    </Container>
  );
};

export default DoctorateView;
