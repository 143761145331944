import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Container } from 'semantic-ui-react';
import { ConditionalLink, Icon as VoltoIcon } from '@plone/volto/components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrowSVG from '@package/svgs/icons/arrow-uniko.svg';
import EventCard from './EventCard';

const messages = defineMessages({
  previousEvents: {
    id: 'Show previous events',
    defaultMessage: 'Show previous events',
  },
  laterEvents: {
    id: 'Show later events',
    defaultMessage: 'Show later events',
  },
});

const CustomLeftArrow = ({ onClick, intl }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      aria-label={intl.formatMessage(messages.previousEvents)}
    >
      <VoltoIcon name={arrowSVG} className="link" size="24px" />
    </button>
  );
};

const CustomRightArrow = ({ onClick, intl }) => {
  return (
    <button
      onClick={() => onClick()}
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      aria-label={intl.formatMessage(messages.laterEvents)}
    >
      <VoltoIcon name={arrowSVG} className="link" size="24px" />
    </button>
  );
};

const CustomButtonGroupAsArrows = ({ next, previous, carouselState }) => {
  const intl = useIntl();
  return (
    <>
      {carouselState.currentSlide > 0 && (
        <CustomLeftArrow onClick={previous} intl={intl} />
      )}
      {carouselState.currentSlide + carouselState.slidesToShow <
        carouselState.totalItems && (
        <CustomRightArrow onClick={next} intl={intl} />
      )}
    </>
  );
};

const EventsCarousel = ({
  items,
  isEditMode,
  link_to_all,
  show_time,
  show_description,
  no_infinity_scroll,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 9999999, min: 1280 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1280, min: 768 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      partialVisibilityGutter: 90,
    },
  };
  return (
    <Fade>
      <div className="events-carousel fullwidth">
        <Container>
          <Carousel
            autoPlay={false}
            shouldResetAutoplay={false}
            responsive={responsive}
            ssr={true}
            infinite={no_infinity_scroll ? false : items.length > 1}
            arrows={false}
            renderButtonGroupOutside
            customButtonGroup={
              items.length > 1 ? <CustomButtonGroupAsArrows /> : <></>
            }
            partialVisible={items.length > 1}
          >
            {items.map((item) => {
              return (
                <EventCard
                  key={item['@id']}
                  event={item}
                  isEditMode={isEditMode}
                  showTime={show_time}
                  showDescription={show_description}
                />
              );
            })}
            {link_to_all && link_to_all.length > 0 && (
              <ConditionalLink
                to={link_to_all[0]['@id']}
                condition={!isEditMode}
              >
                <div className="overview-item">
                  <span>
                    <FormattedMessage
                      id="All events"
                      defaultMessage="All events"
                    />
                    <VoltoIcon name={arrowSVG} className="link" size="24px" />
                  </span>
                </div>
              </ConditionalLink>
            )}
          </Carousel>
          <div className="box-shadow-left"></div>
          <div className="box-shadow-right"></div>
        </Container>
      </div>
    </Fade>
  );
};

export default EventsCarousel;
